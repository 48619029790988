// extracted by mini-css-extract-plugin
export var single = "style-module--single--3GO40";
export var back = "style-module--back--23ysJ";
export var previous = "style-module--previous--1_f5r";
export var categories = "style-module--categories--1-5pO";
export var article = "style-module--article--2oCfq";
export var article__head = "style-module--article__head--3qMVM";
export var featuredImage = "style-module--featured-image--2M3VK";
export var author = "style-module--author--cf3w5";
export var author__image = "style-module--author__image--2hF76";
export var author__name = "style-module--author__name--1bYzZ";
export var date = "style-module--date--1Xu08";
export var article__body = "style-module--article__body--1g_WQ";
export var share = "style-module--share--gAHvp";
export var content = "style-module--content--1UiOD";
export var normalizedPosts = "style-module--normalized-posts--2dSdY";
export var slide = "style-module--slide--YeznL";
export var normalizedPosts__title = "style-module--normalized-posts__title--WQu-X";