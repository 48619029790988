import React from "react"
import Slider from 'react-slick'
import { graphql, Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment"
import { Layout, Seo, SharingButtons, Theme, Container, PostCard } from "../../components"

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as styles from './style.module.scss'

const BlogSingle = ({ data, pageContext }) => {
  const { post, posts } = data

	const carouselSettings = {
		autoPlay: false,
		arrows: false,
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}

  React.useEffect(() => {
    if (pageContext.lang === "en_us") navigate("/")
  }, [pageContext.lang])

  const normalizedPost = {
    categories: post.categories.nodes.filter(
      cat => ![9, 10].includes(cat.databaseId)
    ),
    author: post.blogFields.wannaAuthor?.postAuthorFields,
    blog: post.blogFields?.blogFields?.find(
      variant =>
        variant.languageValue && variant.languageValue.slug === pageContext.lang
    ),
    featured: post.blogFields.isFeatured,
    date: moment(post.date).format("ll"),
    slug: post.slug,
    id: post.databaseId,
  }

  const normalizedPosts = posts.edges.map(post => ({
    categories: post.node.categories.nodes.filter(
      cat => ![9, 10].includes(cat.databaseId)
    ),
    author: post.node.blogFields.wannaAuthor?.postAuthorFields,
    blog: post.node.blogFields?.blogFields?.find(
      variant =>
        variant.languageValue && variant.languageValue.slug === pageContext.lang
    ),
    featured: post.node.blogFields.isFeatured,
    date: moment(post.date).format("ll"),
    slug: post.node.slug,
    id: post.node.databaseId,
  }))

  return (
    <Layout>
      {normalizedPost && normalizedPost.blog ? (
        <Seo
          title={normalizedPost.blog.blogTitle}
          description={normalizedPost.blog.excerpt}
          image={
            normalizedPost.blog.blogImage
              ? normalizedPost.blog.blogImage.localFile.childImageSharp
                  .gatsbyImageData.src
              : null
          }
        />
      ) : null}

			<section className={styles.single}>
				<Container>
					<div className={styles.back}>
						<a className={styles.previous} onClick={() => navigate(-1)}>
							<svg width="6" height="10" viewBox="0 0 6 10" fill="none">
								<path d="M2.21834 4.99999L5.51834 8.29999L4.57567 9.24266L0.333008 4.99999L4.57567 0.757324L5.51834 1.69999L2.21834 4.99999Z" fill="white"/>
							</svg>

							{pageContext.lang === "en_us" ? "Go back" : "Voltar"}
						</a>

						<div className={styles.categories}>
							{normalizedPost.categories.map(cat =>
								<Link
									key={cat.databaseId}
									className="pill"
									to={`/${pageContext.lang === "en_us" ? "en" : "pt"}/blog/${cat.slug}/`}
								>
									{cat.name}
								</Link>
							)}
						</div>
					</div>

					{ normalizedPost && normalizedPost.blog &&
						<article className={styles.article}>
							<header className={styles.article__head}>
								<h1>{normalizedPost.blog.blogTitle}</h1>

								<div className={styles.author}>
									<div className={styles.author__image}>
										<GatsbyImage
											image={normalizedPost.author.image?.localFile.childImageSharp.gatsbyImageData}
											alt={normalizedPost.author.name}
										/>
									</div>
									<div className="flex">
										<p className={styles.author__name}>{normalizedPost.author.name}</p>
										<p className={styles.date}>{normalizedPost.date} • {normalizedPost.blog.readingTime ?? "1 min"} {(pageContext.lang === "en_us") ? 'read' : 'leitura'}</p>
									</div>
								</div>

								<div className={styles.featuredImage}>
									{normalizedPost.blog.blogImage &&
										<GatsbyImage
											image={normalizedPost.blog.blogImage.localFile.childImageSharp.gatsbyImageData}
											alt={normalizedPost.blog.blogTitle}
										/>
									}
								</div>
							</header>

							<div className={styles.article__body}>
								<SharingButtons className={styles.share} />

								<div
									className={styles.content}
									dangerouslySetInnerHTML={{__html: normalizedPost.blog.content}}
								></div>
							</div>
						</article>
					}

					{
						normalizedPosts.length > 0 &&
						<div className={styles.normalizedPosts}>
							<p className={styles.normalizedPosts__title}>
								{(pageContext.lang === "en_us" ? "WANNA BLOG" : "BLOG DO WANNA")}
							</p>

							<hr />

							<Slider {...carouselSettings}>
								{
									normalizedPosts.map(post =>
										post && post.blog &&
										<PostCard className={styles.slide} post={post} key={post.id} pageContext={pageContext} />
									)
								}
							</Slider>
						</div>
					}
				</Container>
			</section>
    </Layout>
  )
}

export const Query = graphql`
  query BlogSingle($id: Int!, $cat: Int!) {
    post: wpPost(databaseId: { eq: $id }) {
      categories {
        nodes {
          databaseId
          slug
          name
          parentDatabaseId
        }
      }
      date
      slug
      databaseId
      blogFields {
        isFeatured
        wannaAuthor {
          ... on WpAuthor {
            postAuthorFields {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 60, layout: FIXED)
                  }
                }
              }
              link
              name
            }
          }
        }
        blogFields {
          blogTitle
          blogImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1074
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
          content
          excerpt
          languageValue {
            slug
          }
          readingTime
        }
      }
    }
    posts: allWpPost(
      limit: 3,
      filter: {
        status: {eq: "publish"},
        databaseId: {ne: $id},
        categories: {
          nodes: {
            elemMatch: {
              databaseId: {eq: $cat}
            }
          }
        },
        blogFields: {
          blogFields: {
            elemMatch: {
              blogTitle: {ne: null},
              blogImage: {
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {ne: "null"}
                  }
                }
              },
              excerpt: {ne: null},
              languageValue: {
                slug: {ne: null}
              },
              readingTime: {ne: null}
            }
          }
        }
      }
    ) {
      edges {
        node {
          categories {
            nodes {
              databaseId
              slug
              name
              parentDatabaseId
            }
          }
          date
          slug
          databaseId
          blogFields {
            isFeatured
            wannaAuthor {
              ... on WpAuthor {
                postAuthorFields {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 80, layout: FIXED)
                      }
                    }
                  }
                  link
                  name
                }
              }
            }
            blogFields {
              blogTitle
              blogImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 600, layout: FIXED)
                  }
                }
              }
              excerpt
              languageValue {
                slug
              }
              readingTime
            }
          }
        }
      }
    }
  }
`

export default BlogSingle
